export default {
  validate: {
    email: "Email chưa đúng định dạng, vui lòng nhập lại!",
    min: "Vui lòng nhập tối thiểu {number} ký tự!",
    max: "Vui lòng nhập tối đa {number} ký tự!",
    max2: "Số điện thoại phải {min}<=SĐT<={max}",
    required: "Vui lòng điền {filed}!",
    numeric: "Vui lòng nhập số!",
    number: "{filed} không được chứa ký tự đặc biệt. Vui lòng chỉ nhập số!",
    file_or_link: "Bạn chưa upload file hoặc gắn link!",
    date: "Ngày và giờ hiển thị không được chọn ngày quá khứ!",
    date_compare: "Ngày kết thúc phải sau ngày bắt đầu!",
    required_class_room: "Vui lòng chọn lớp để copy!",
    confirmed: "Xác nhận mật khẩu không khớp!",
    size: "{filed} tải lên có dung lượng <= {number}",
    phone_is_valid: "Số điện thoại không hợp lệ",
    unique: "{field} đã tồn tại",
    image: "Upload ảnh không đúng định dạng",
    reason_trim: "Vui lòng không nhập khoảng trắng 2 đầu dòng.",
    mark: "Điểm số không hợp lệ",
    type_file: "File tải lên phải có định dạng file excel",
    max_address: "Địa chỉ không vượt quá 255 ký tự",
    password_old_not_same: "Mật khẩu mới không được trùng mật khẩu cũ",
    invalid_format: "{field} không hợp lệ!",
    name: "Tên ",
  },
  type_exam: {
    name: "Tên loại đề thi",
    duration: "Thời gian thi",
    total_score: "Tổng số câu",
    quantity: "Tổng điểm",
  },
  category: {
    name: "Tên chuyên mục",
    slug: "Slug",
  },
  admin: {
    email: "Email ",
    password: "Mật khẩu ",
    user_name: "Tên tài khoản ",
    roles: "Vai trò ",
    company_id: "Đơn vị ",
  },
  company: {
    name: "Tên đơn vị ",
  },

  hello: "XIn Chao",
  action: "THAO TÁC",
  menu: {
    exam: {
      title: "Quản lý đề thi",
      child: {
        category: "Chuyên mục",
        type: "Loại đề thi",
        exam: "Đề thi",
        exam_ecommerce: "Đề thi bán",
        draft: "Bản nháp",
      },
    },
    schedule: "Quản lý lịch thi",
    company: "Quản lý đơn vị",
    admin: "Quản lý admin",
    api_key: "Quản lý api key",
    user: "Quản lý người dùng",
    roles: "Quản lý vai trò",
  },
  logout: "Đăng xuất",
  login: {
    description:
      "Nền tảng Thi trắc nghiệm, Trộn và Tạo đề thi miễn phí nhiều người sử dụng nhất",
    title: "Đăng nhập",
    email: "Nhập email",
    password: "Nhập mật khẩu",
  },
};
