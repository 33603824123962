<template>
  <div>
<!--    <div v-if="type == 'exam'">-->
<!--      <div v-for="(item,index) in current_list_question" :key="index" class="mb-5">-->
<!--        <CreateQuestion :openCheck="openCheck" @clearQuestion="clearQuestion" @addAudio="addAudio"-->
<!--                        :index_question="index" :question="item" :color="months[indexTree]" :index="indexTree">-->
<!--        </CreateQuestion>-->
<!--      </div>-->
<!--    </div>-->
    <div >
      <div v-for="(item,index) in current_list_question" :key="index" class="mb-5">
        <CreateTemporaryQuestion :openCheck="openCheck" @clearQuestion="clearQuestion" @addAudio="addAudio"
                                 :index_question="index" :question="item" :color="months[indexTree]" :index="indexTree"
        @copyQuestion="copyQuestion">
        </CreateTemporaryQuestion>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import CreateQuestion from "@/components/elements/CreateQuestion.vue";
// import CreateTemporaryQuestion from "@/components/question/CreateTemporaryQuestion.vue";
// import ListQuestion from "@/components/question/ListQuestion.vue";
import {defineEmits, defineProps, provide, reactive, ref, toRef} from "vue";
import {useRoute} from "vue-router";

const emit = defineEmits(['clearQuestion'])
const props = defineProps({
  list_question: {
    type: Array,
    // eslint-disable-next-line vue/require-valid-default-prop
    default: [],
  },
  indexTree: {
    type: Number,
    default: 0
  },
  openCheck: {
    type: Boolean,
    default: true
  }
})

// const openCheck = ref(false)
//
// provide('openCheck', openCheck)

interface Exam {
  title: string,
  exam_intro_id: number | null,
  questions: Array<object> | null,
  slug: string,
  seo: object | null,
}

const route = useRoute();
const type = route.query.type;
const current_list_question: any = toRef(props, 'list_question')
const exam = reactive<Exam>({
  title: '',
  exam_intro_id: null,
  questions: [],
  slug: '',
  seo: {},
})

const months = ["#73B566", "#E6AE5F", "#CA5B54", "#003769", "#C46E9B"];

function copyQuestion(data) {
  current_list_question.value.push(data)
}

function addAudio(payload) {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  try {
    if (current_list_question.value) {
      current_list_question.value.forEach((el: any) => {
        if (el.id === payload.question_id) {
          el.media_id = payload.data.media_id;
          el.media = {
            id: payload.data.media_id,
            path: payload.data.path,
            url: payload.data.url,
          };
        } else if (el.random_id === payload.question_id) {
          el.media_id = payload.data.media_id;
          el.media = {
            id: payload.data.media_id,
            path: payload.data.path,
            url: payload.data.url,
          };
        }
      })
    }
  } catch (e) {
    console.error(e)
  }
}

function clearQuestion(data) {
  let questions = current_list_question.value?.filter(v => {
    if (v.random_id) {
      return v.random_id != data.question_id
    } else {
      return v.id != data.question_id
    }
    // return  v.random_id?v.random_id:v.id != data.question_id
  })
  emit('clearQuestion', questions)
  // console.log(data)
  // console.log(current_list_question.value)
  // current_list_question.value.forEach((v,i)=> {
  //   console.log(i)
  // })
}

</script>

<style scoped lang="scss">
</style>
