import {createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw} from 'vue-router';
import JwtService from "@/common/jwt.service";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: "/home",
        name: '',
        component: () => import("@/components/layouts/Dashboard.vue"),
        children: [
            {
                path: "/home",
                component: () => import("@/views/home.vue")
            },
            {
                path: "/category/list",
                component: () => import("@/views/exam/category/list_category.vue"),
                meta: {key:'category' },
            },
            {
                path: "/category/update/:id",
                component: () => import("@/views/exam/category/update_category.vue"),
                meta : {key:'category' },
            },
            {
                path: "/category/add",
                component: () => import("@/views/exam/category/add_category.vue"),
                meta : {key:'category' },
            },
            {
                path: "/type_exam/list",
                component: () => import("@/views/exam/type_exam/list_type_exam.vue"),
                meta : {key:'type_exam' },
            },
            {
                path: "/type_exam/update/:id",
                component: () => import("@/views/exam/type_exam/update_type_exam.vue"),
                meta : {key:'type_exam' },
                
            },
            {
                path: "/type_exam/add",
                component: () => import("@/views/exam/type_exam/add_type_exam.vue"),
                meta : {key:'type_exam' },
            },
            {
                path: "/exam/list_exam",
                component: () => import("@/views/exam/exams/list_exam.vue"),
                meta : {key:'exam' },
            },
            {
                path: "/exam/list_exam_ecommerce",
                component: () => import("@/views/exam/exams/list_exam_ecommerce.vue"),
                meta : {key:'exam_ecommerce' },
            },
            {
                path: "/exam/add_exam",
                component: () => import("@/views/exam/exams/add_exam.vue"),
                meta : {key:'exam' },
            },
            {
                path: "/exam/update_exam/:id",
                component: () => import("@/views/exam/exams/update_exam.vue"),
                meta : {key:'exam' },
            },
            {
                path: "/test",
                component: () => import("@/views/test.vue")
            },
            {
                path: "/exam/create_exam/:id",
                component: () => import("@/views/exam/exams/create_exam.vue"),
                meta : {key:'exam' },
            },
            {
                path: "/list_admins",
                component: () => import("@/views/admins/list_admins.vue"),
                meta : {key:'admin' },
            },
            {
                path: "/add_admin",
                component: () => import("@/views/admins/add_admin.vue"),
                meta : {key:'admin' },
            },
            {
                path: "/detail_admin/:id",
                component: () => import("@/views/admins/detail_admin.vue"),
                meta : {key:'admin' },
            },
            {
                path: "/list_users",
                component: () => import("@/views/users/list_users.vue"),
                meta : {key:'users' },
            },
            {
                path: "/add_user",
                component: () => import("@/views/users/add_user.vue"),
                meta : {key:'users' },
            },
            {
                path: "/detail_user/:id",
                component: () => import("@/views/users/detail_user.vue"),
                meta : {key:'users' },
            },
            {
                path: "/temporary_exam/list_temporary_exam",
                component: () => import("@/views/exam/temporary_exams/list_temporary_exam.vue"),
                meta : {key:'draft' },
            },

        ]
    },
    {
        path: '/',
        name: 'companies',
        component: () => import("@/components/layouts/Dashboard.vue"),
        children: [
            {
                path: "/companies/list",
                component: () => import("@/views/companies/list_company.vue"),
                meta: {key:'company' },
            },
            {
                path: "/companies/add",
                component: () => import("@/views/companies/add_company.vue"),
                meta: {key:'company' },
            },
            {
                path: "/companies/update/:id",
                component: () => import("@/views/companies/update_company.vue"),
                meta: {key:'company' },
            },
        ]
    },
    {
        path: '/',
        name: 'personal_access_keys',
        component: () => import("@/components/layouts/Dashboard.vue"),
        children: [
            {
                path: '/personal_access_keys/list',
                name: 'list_personal_access_key',
                component: () => import('@/views/personal_access_keys/list_personal_access_key.vue'),
                meta: {key:'personal_access_keys' },
            }
        ]
    },
    {
        path: '/',
        name: 'roles',
        component: () => import("@/components/layouts/Dashboard.vue"),
        children: [
            {
                path: '/roles/list',
                name: 'list_role',
                component: () => import('@/views/roles/list_role.vue'),
                meta: {key:'roles' },
            }
        ]
    },
    {
        path: '/',
        name: 'auth',
        component: () => import("@/views/auth/Auth.vue"),
        children: [
            {
                path: '/login',
                name: 'login',
                component: () => import('@/views/auth/login.vue')
            },
            {
                path: '/forgot-password',
                name: 'forgot-password',
                component: () => import('@/views/auth/forgot_password.vue')
            },
            {
                path: '/update-password',
                name: 'update-password',
                component: () => import('@/views/auth/update_password.vue')
            },
        ]
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})
router.beforeEach(async(to, from, next) => {
    const array: Array<string> = ['login', 'forgot-password','update-password']
    if (JwtService.getToken()){
        if (to.name == 'login'){
            next('/')
        }
        else {
            next()
        }
    }
    else {
        if ( (typeof to.name == 'string' && !array.includes(to.name)) || to.name == undefined){
            next('/login')
        }else {
            next()
        }
    }
})
// @ts-ignore
export default router


